// src/components/HomePage.js

import React from 'react';
import './HomePage.css';  // Optionnel: si vous avez des styles spécifiques à cette page

const HomePage = () => {
    return (
        <div className="home-page">
            <h1>Bienvenue sur Vinymatic</h1>
            <p>Votre destination ultime pour gérer et explorer votre collection de vinyles.</p>
            <h2>À propos de Vinymatic</h2>
            <p>Vinymatic vous offre une plateforme intuitive pour cataloguer, analyser et découvrir de nouveaux vinyles. Profitez de nos fonctionnalités premium pour une expérience enrichissante.</p>
            <h2>Fonctionnalités Principales</h2>
            <ul>
                <li>Gestion de collection de vinyles</li>
                <li>Recommandations personnalisées</li>
                <li>Analyse et statistiques détaillées</li>
                <li>Accès à une communauté de passionnés</li>
            </ul>
        </div>
    );
};

export default HomePage;
