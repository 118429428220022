import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './SousSousRubriquePage.css';  // Importer le fichier CSS

const SousSousRubriquePage = () => {
    const { id } = useParams();  // Obtenez l'ID de la sous-sous-rubrique depuis les paramètres de l'URL
    const [sousSousRubrique, setSousSousRubrique] = useState(null);

    useEffect(() => {
        axios.get(`https://support.vinymatic.com/api/content/${id}/`)
            .then(response => {
                setSousSousRubrique(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération de la sous-sous-rubrique:', error);
            });
    }, [id]);

    if (!sousSousRubrique) return <p>Chargement...</p>;

    // URL de base du serveur
    const baseURL = 'https://support.vinymatic.com';

    // Remplacement des chemins relatifs des images par des chemins absolus
    const descriptionWithImages = sousSousRubrique.description.replace(/\/media\//g, `${baseURL}/media/`);

    return (
        <div className="sous-sous-rubrique-container">
            {/* Affichage du contenu HTML avec les chemins d'images mis à jour */}
            <div dangerouslySetInnerHTML={{ __html: descriptionWithImages }} />
        </div>
    );
};

export default SousSousRubriquePage;
